import { IStore } from "types/IStore";

export const stores: IStore[] = [];

const storesNames = process.env.REACT_APP_STORES?.split(";");

if (storesNames) {
  storesNames.forEach((storeName) => {
    const configs = process.env[`REACT_APP_${storeName}`]?.split(";");

    if (configs) {
      // name;base_api_url;store_hash
      const [name, baseUrl, storeHash] = configs;
      stores.push({
        name,
        storeHash,
        baseUrl,
      });
    }
  });
}
