import { useCallback } from "react";

import { StoreState } from "states/StoreState";

import { IStore } from "types/IStore";

export const useCurrentStore = () => {
  const { currentStore } = StoreState.useState();

  const setCurrentStore = useCallback((newCurrentStore: IStore) => {
    StoreState.update((state) => {
      state.currentStore = newCurrentStore;
    });
  }, []);

  return { currentStore, setCurrentStore };
};
