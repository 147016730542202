import { AppBar, Toolbar, Typography } from "@mui/material";
import { useMemo } from "react";

import { useIsAuthenticated, useUser } from "hooks/userHooks";

export const Header = () => {
  // Hooks
  const isAuthenticated = useIsAuthenticated();
  const user = useUser();

  // Memos
  const firstName = useMemo(() => {
    if (user?.name) {
      return user?.name.split(" ")[0];
    }

    return "";
  }, [user?.name]);

  // Returns
  return (
    <AppBar variant="elevation" elevation={1} position="sticky">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: 2 }}>
          {isAuthenticated ? `Olá, ${firstName}` : "Bem vindo"}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
