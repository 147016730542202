import { Box, Container } from "@mui/material";
import { useCallback } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { addManifest } from "helpers/pwa";
import { stores } from "helpers/stores";

import { useCurrentStore } from "hooks/storeHooks";
import { useIsAuthenticated, useUserAuthenticatedStoreHash } from "hooks/userHooks";

import { UserState } from "states/UserState";

import { IRoutes } from "types/IRoutes";
import { IUser } from "types/IUser";

import { BottomMenu } from "./components/BottomMenu";
import { Header } from "./components/Header";

export const Main = () => {
  // Hooks
  const { storeHash } = useParams();
  const { setCurrentStore } = useCurrentStore();
  const userStoreHash = useUserAuthenticatedStoreHash();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  // Fns
  const removeAuthData = useCallback(() => {
    UserState.update((state) => {
      state.accessToken = "";
      state.user = {} as IUser;
      state.storeHash = "";
    });
  }, []);

  // Effects
  useEffectOnce(() => {
    const store = stores.find((item) => item.storeHash === storeHash);

    if (!store) {
      navigate(IRoutes.NOT_FOUND, { replace: true });
      return;
    }

    document.title = store.name;
    setCurrentStore(store);
    addManifest(store);
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`);
    }

    if (isAuthenticated && userStoreHash !== storeHash) removeAuthData();

    navigate(IRoutes.HOME);
  });

  // Returns
  return (
    <>
      <Header />

      <Container sx={{ mt: 2 }}>
        <Outlet />
        <Box sx={{ height: 56, mt: 2 }} />
      </Container>

      <BottomMenu />
    </>
  );
};
